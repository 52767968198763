import React from "react";
import Mycontact from "./../../components/Contact/Contact";

export const Contact = () => {
  return (
    <React.Fragment>
            
        <Mycontact />
        
        </React.Fragment>
  );
};
