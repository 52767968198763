
  
 export const data = [
    
    {  
      number: 1,
      title: 'Gentleboard (Real Estate)',
      image: '/images/gentle.jpg',
    visit: 'https://gentleboard.org/',
       },
    { 
        number: 2, 
        title: 'SpinettCosmetics (Cosmetics Store)',
          image: '/images/spinett.jpg',
        visit: 'https://spinettcosmetics.ng/',
      },
     
    { 
        number: 3,
        title: 'Ifitech Ltd (Real Estate)',
        image: '/images/ifitech.jpg',
      visit: 'https://ifitechltd.com/',
       },
       
       { 
        number: 4,
        title: 'Luxury Cargo (Shipping)',
        image: '/images/luxury.jpg',
      visit: 'https://luxurycargo.co.uk/',
       },
       { 
        number: 5,
        title: 'Graceway Austin (Church)',
        image: '/images/graceway.jpg',
      visit: 'https://gracewayaustin.org/',
       },
       
       
       { 
        number: 6,
        title: 'Solidbasegardens (Real Estate)',
        image: '/images/solidbase.jpg',
      visit: 'https://solidbasegardens.com',
       },
       
       { 
        number: 7,
        title: 'Demichaels Oil & Gas (Energy)',
        image: '/images/demichaels.jpg',
      visit: 'https://Demichealsoilandgasltd.com/',
       },
       { 
        number: 8,
        title: 'Tudu Energy (Energy)',
        image: '/images/tudu.jpg',
      visit: 'https://tuduenergy.com',
       },
       
       
       
       
  ];