
  
 export const myapps = [
    { 
        number: 1,
        title: 'Bookit (E-Learning)',
          image: '/images/bookit.jpg',
        visit: '#',
      },
    {
        
      number: 2,
      title: 'Food Delivery (food)',
      image: '/images/food delivery.jpg',
    visit: '#',
       },
       { 
        
        number: 3,
        title: 'LaundryOp (Laundry)',
        image: '/images/laundryop.jpg',
      visit: '#',
         },
         {
        
          number: 4,
          title: 'Cardio (Health)',
          image: '/images/cardio.jpg',
        visit: '#',
           },
           {
        
            number: 5,
            title: 'Taxi (Transport)',
            image: '/images/taxi.jpg',
          visit: '#',
             },
    
       
       
  ];