import React from "react";
import Hero from "./../../components/Hero/Hero";




export const Home = () => {
  return (
   <React.Fragment>
            
   <Hero />
  
   </React.Fragment>
    
    
  );
};

