import React from "react";
import Myservices from "./../../components/Services/Services";
import Footer from "./../../components/Footer/Footer";

export const Services = () => {
  return (
    <React.Fragment>
            
        <Myservices />
        <Footer />
        
        </React.Fragment>
  );
};
