import React from "react";
import Myblog from "./../../components/Blog/Blog";
import Footer from "./../../components/Footer/Footer";

export const Blog = () => {
  return (
    <React.Fragment>
            
        <Myblog />
        <Footer />
        
        </React.Fragment>
  );
};
