import React from "react";
import Myprojects from "./../../components/Projects/Projects";
import Footer from "./../../components/Footer/Footer";

export const Projects = () => {
  return (
    <React.Fragment>
            
        <Myprojects />
        <Footer />
        
        </React.Fragment>
  );
};
