
  
 export const mydesign = [
    { 
        number: 1,
        title: 'Bearthy (logo)',
          image: '/images/bearthy.jpg',
        visit: '#',
      },
    {
        
      number: 2,
      title: 'Banana (art)',
      image: '/images/banana.jpg',
    visit: '#',
       },
    { 
        number: 3, 
        title: 'Moi moi(Packaging)',
          image: '/images/food1.jpg',
        visit: '#',
      },
    { 
        number: 4,
        title: '250 (Branding)',
        image: '/images/drink.jpg',
      visit: '#',
       },

       { 
        number: 4,
        title: 'Saladbolash (Business Card)',
        image: '/images/salad.jpg',
      visit: 'https://www.behance.net/gallery/131285207/Salad-Bolash-Business-Card',
       },
       
       
  ];