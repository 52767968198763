import React from "react";
import Aboutme from "./../../components/About/About";
import Footer from "./../../components/Footer/Footer";


export const About = () => {
  return (
    <React.Fragment>
            
        <Aboutme />
        <Footer />
        
        </React.Fragment>
  );
};
