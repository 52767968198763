import React from "react";
import Mystack from "./../../components/Stack/Stack";
import Footer from "./../../components/Footer/Footer";

export const Stack = () => {
  return (
    <React.Fragment>
            
        <Mystack />
        <Footer />
        
        </React.Fragment>
  );
};
